@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

*{
  padding: 0;
  margin: 0;
}

.error-text{
  color: rgb(226, 30, 30);
  font-family: Roboto;
  opacity: 0.8;
  padding-top: 8px;
  font-size: 13px;
  font-style: italic;
}

.applicationformmicrosite-container{
  background: #e9c4c4;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.banner-card{
  width: 700px; 
  height: 200px; 
  margin-top: 50px; 
  align-items: center; 
  display: flex;
  border-top: 6px solid red;
}

.field-card{
  width: 700px;
  margin-top: 14px;
}

.applicationform-logo{
  height: 80%;
  margin-left: 20px;
}


.applicationform-title:nth-child(2){
  margin-top: 10px;
}

table {
  border-spacing: 0;
}

tr, td {
  margin: 0;
  padding: 0;
}

tr td {
  padding: 6px 8px;
  border: 2px solid rgb(219, 218, 218);
}

@media only screen and (max-width: 700px) {
  .banner-card{
      width: 90%; 
      height: 170px; 
  }

  .field-card{
      width: 90%;
  }

  .applicationform-title{
      font-size: 18px !important;
  }

  .applicationform-logo{
      height: 80%;
  }

}